/*
test case (MT1):
https://localmonkey.com/r2/survey-closed?sm=oJH2uafMAEw6Q2_2BDjw_2F8_2F4yWe0n_2Bf9YMne7Ds_2BgImuhYR7P4vs5XHiMcSDfo0491MTUPtcc3gsDWOwn7HGbCWgzxy0tDUe175EpAzdsgT5Q_3D
 */
import React, { useEffect } from 'react';
import { GetServerSidePropsResult, Redirect } from 'next';
import Head from 'next/head';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import { t, defineMessages } from '@sm/intl';
import { ThemeProvider } from 'react-jss';

import routeHandler from '~helpers/routeHandler';
import { SmParams, PageWithLayout } from '~helpers/pages/types';
import { buildSmOptions } from '~helpers/buildSmOptions';
import surveyClosed, { SurveyClosedServerSideData, PageVariant } from '~helpers/pages/survey-closed';
import fetchLocaleMessages from '~helpers/fetchLocaleMessages';
import BrandedSurveyClosed from '~app/pages/SurveyClosed/BrandedSurveyClosed';
import EUBrandedSurveyClosed from '~app/pages/SurveyClosed/EUBrandedSurveyClosed';
import NonBrandedSurveyClosed from '~app/pages/SurveyClosed/NonBrandedSurveyClosed';
import BrandedEmbeddedSurveyClosed from '~app/pages/SurveyClosed/BrandedEmbeddedSurveyClosed';
import EmbeddedNonBrandedHeader from '~app/components/EmbeddedNonBrandedHeader';

import theme, { useGlobalStyles } from '~app/styles/cmsTheme';
import { CustomGSSPContext, LayoutData } from '~helpers/middleware/types';
import OneTrustFooterScript from '~components/oneTrustScript';
import PageLayout from '~components/pageLayout';

const COPY = defineMessages({
  DEFAULT_CLOSED_MESSAGE: {
    id: 'survey-closed.closedMessage',
    defaultMessage:
      'This survey is currently closed.  Please contact the author of this survey for further assistance.',
  },
  CLOSED_PAGE_TITLE: {
    id: 'survey-closed.title',
    defaultMessage: 'Free Online Survey Software by SurveyMonkey: Closed Survey',
  },
  CLOSED_PAGE_NON_BRANDED_TITLE: {
    id: 'survey-closed.nonBrandedTitle',
    defaultMessage: 'Online Survey Software: Closed Survey',
  },
});

const fallbackCollectorData = {
  collector: {
    id: null,
    type: null,
    hideBranding: true,
    closedPage: {
      closedMessage: t(COPY.DEFAULT_CLOSED_MESSAGE),
      isBrandingDisabled: true,
    },
  },
};

const redirect: Redirect = {
  destination: '/mp/online-research/',
  permanent: true, // 301 status
};

type ServerSideProps = LayoutData & {
  canonical: string;
  canonicalHostname: string;
  customMessage: string | undefined;
  pageVariant: PageVariant;
  showFooter: boolean;
  smParams?: SmParams;
};

export const getServerSideProps = async ({
  req,
  res,
  query,
  resolvedUrl,
}: CustomGSSPContext<ServerSideProps & SurveyClosedServerSideData>): Promise<
  GetServerSidePropsResult<ServerSideProps>
> => {
  const handler = routeHandler({
    query,
    pageDataLoader: surveyClosed,
    owners: '@webplatform/content',
    onetrustBannerEnabled: true,
  });
  if (!query?.sm) {
    return { redirect }; // redirect without SMParam
  }

  await handler.run(req, res);
  const { collector } = req?.pageProps?.collectorData ?? fallbackCollectorData;
  const smParams = buildSmOptions(query)?.smParams;

  const isNonBranded =
    req?.pageProps?.pageOptions?.isNonBrandedSmParam === true ||
    collector?.hideBranding ||
    collector?.closedPage?.isBrandingDisabled ||
    req?.pageProps?.pageOptions?.isWhiteLabel;
  const isEmbedded = req?.pageProps?.pageOptions?.isEmbeddedSmParam || collector?.type?.toUpperCase() === 'POPUP';
  const showFooter = !(isNonBranded || isEmbedded);
  const isEUSubdomain = req?.payloads?.staticData?.locale.isEUSubdomain === true;
  const { canonical = '', canonicalHostname = '' } = req?.pageProps?.pageOptions || {};
  const customMessage = collector?.closedPage?.closedMessage;
  const { slLanguageLocale, languageCode } = req.payloads?.staticData?.environment ?? {};
  const translationData = await fetchLocaleMessages(slLanguageLocale, languageCode);

  let pageVariant: PageVariant = 'branded';
  if (isEmbedded) {
    pageVariant = isNonBranded ? 'embeddedNonBranded' : 'embeddedBranded';
  } else if (isNonBranded) {
    pageVariant = 'nonbranded';
  } else if (isEUSubdomain) {
    pageVariant = 'brandedEU';
  }

  // TODO: Retire the usage of StaticContext and use page props instead.
  // Additional page-specific (reCAPTCHA) data needed in StaticContext
  req.addClientPayload('staticData', {
    recaptchaV3: {
      enabled: process?.env?.SECURITY_RECAPTCHA_V3_ENABLED?.toLowerCase() === 'true' ?? false,
      siteKey: process?.env?.SECURITY_RECAPTCHA_V3_SITE_KEY ?? '',
      url: process?.env?.SECURITY_RECAPTCHA_V3_URL ?? '',
      urlChina: process?.env?.SECURITY_RECAPTCHA_V3_URL_CHINA ?? '',
      useEnterprise: process?.env?.SECURITY_RECAPTCHA_V3_USE_ENTERPRISE?.toLowerCase() === 'true' ?? false,
    },
    smParams,
    templateType: pageVariant,
  });

  return {
    props: {
      // added to the request via routeHandler
      ...req.payloads,
      // added to the request via the pageDataLoaderFactory
      ...req.pageProps,
      translationData,
      layout: {
        variant: 'SurveyMonkey',
        options: {
          pageId: 'Survey Closed',
          legacyWeb: 'anonweb',
          includeHeader: false,
          includeFooter: showFooter,
          includeGTM: true,
        },
      },
      canonical,
      canonicalHostname,
      customMessage,
      pageVariant,
      showFooter,
      smParams,
    },
  };
};

type SurveyClosedBodyProps = {
  endpageMessage: string;
  pageVariant: string;
};

const SurveyClosedBody = ({ endpageMessage, pageVariant }: SurveyClosedBodyProps): React.ReactElement => {
  useGlobalStyles();
  if (pageVariant === 'branded') {
    return <BrandedSurveyClosed message={endpageMessage} />;
  }
  if (pageVariant === 'embeddedBranded') {
    return <BrandedEmbeddedSurveyClosed message={endpageMessage} />;
  }
  if (pageVariant === 'embeddedNonBranded') {
    return <EmbeddedNonBrandedHeader message={endpageMessage} icon="closed" />;
  }
  if (pageVariant === 'nonbranded') {
    return <NonBrandedSurveyClosed message={endpageMessage} />;
  }
  if (pageVariant === 'brandedEU') {
    return <EUBrandedSurveyClosed message={endpageMessage} />;
  }
  return <NonBrandedSurveyClosed message={endpageMessage} />;
};

const SurveyClosed: PageWithLayout<ServerSideProps> = ({ pageVariant, customMessage, smParams, canonicalHostname }) => {
  const defaultText: { [key: string]: string } = {
    default: t(COPY.CLOSED_PAGE_TITLE),
    embeddedNonBranded: t(COPY.CLOSED_PAGE_NON_BRANDED_TITLE),
    embeddedBranded: t(COPY.CLOSED_PAGE_TITLE),
    nonbranded: t(COPY.CLOSED_PAGE_NON_BRANDED_TITLE),
    isEU: t(COPY.CLOSED_PAGE_TITLE),
  };
  const endpageMessage: string = customMessage || defaultText[pageVariant];

  useEffect(() => {
    MetricsTracker.track({
      name: USER_EVENTS.PAGE_VIEW,
      data: {
        amplitudeEvent: 'page viewed',
        pageName: 'survey closed',
        templateType: pageVariant,
        surveyId: smParams?.surveyId,
        collectorId: smParams?.collectorId,
        respondentId: smParams?.respondentId,
        urlPath: window.location.pathname,
        app: 'respweb',
        log: {
          message: pageVariant,
        },
      },
    });
  }, [pageVariant, smParams]);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <base target="_top" href="/" />
        <link rel="canonical" href={`${canonicalHostname}/survey-closed/`} />
        <title>{defaultText[pageVariant]}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <SurveyClosedBody endpageMessage={endpageMessage} pageVariant={pageVariant} />
      <OneTrustFooterScript />
    </ThemeProvider>
  );
};

SurveyClosed.getLayout = function getLayout(page) {
  const { layout, staticData, translationData } = page.props;

  return (
    <PageLayout layout={layout} staticData={staticData} translationData={translationData}>
      {page}
    </PageLayout>
  );
};

export default SurveyClosed;
